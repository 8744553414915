import React from "react";
import { Link } from "react-router-dom";

function How() {
  return (
    <>
      <div
        className="modal applyLoanModal fade"
        id="applyLoan"
        tabindex="-1"
        aria-labelledby="applyLoanLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h4 className="modal-title" id="exampleModalLabel">
                How much do you need?
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="#!" method="post">
                <div className="row">
                  <div className="col-lg-6 mb-4 pb-2">
                    <div className="form-group">
                      <label for="loan_amount" className="form-label">
                        Amount
                      </label>
                      <input
                        type="number"
                        className="form-control shadow-none"
                        id="loan_amount"
                        placeholder="ex: 25000"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4 pb-2">
                    <div className="form-group">
                      <label for="loan_how_long_for" className="form-label">
                        How long for?
                      </label>
                      <input
                        type="number"
                        className="form-control shadow-none"
                        id="loan_how_long_for"
                        placeholder="ex: 12"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 pb-2">
                    <div className="form-group">
                      <label for="loan_repayment" className="form-label">
                        Repayment
                      </label>
                      <input
                        type="number"
                        className="form-control shadow-none"
                        id="loan_repayment"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4 pb-2">
                    <div className="form-group">
                      <label for="loan_full_name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="loan_full_name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4 pb-2">
                    <div className="form-group">
                      <label for="loan_email_address" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control shadow-none"
                        id="loan_email_address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-primary w-100">
                      Get Your Loan Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <section className="page-header bg-tertiary">
        <div className="container">
          <div className="row">
            <div className="col-8 mx-auto text-center">
              <h2 className="mb-3 text-capitalize">How It Works</h2>
              <ul
                className="list-inline breadcrumbs text-capitalize"
                style={{ fontWeight: "500" }}
              >
                <li className="list-inline-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="list-inline-item">
                  / &nbsp; <Link to="/how">How it works</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="has-shapes">
          <svg
            className="shape shape-left text-light"
            viewBox="0 0 192 752"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-30.883 0C-41.3436 36.4248 -22.7145 75.8085 4.29154 102.398C31.2976 128.987 65.8677 146.199 97.6457 166.87C129.424 187.542 160.139 213.902 172.162 249.847C193.542 313.799 149.886 378.897 129.069 443.036C97.5623 540.079 122.109 653.229 191 728.495"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M-55.5959 7.52271C-66.0565 43.9475 -47.4274 83.3312 -20.4214 109.92C6.58466 136.51 41.1549 153.722 72.9328 174.393C104.711 195.064 135.426 221.425 147.449 257.37C168.829 321.322 125.174 386.42 104.356 450.559C72.8494 547.601 97.3965 660.752 166.287 736.018"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M-80.3302 15.0449C-90.7909 51.4697 -72.1617 90.8535 -45.1557 117.443C-18.1497 144.032 16.4205 161.244 48.1984 181.915C79.9763 202.587 110.691 228.947 122.715 264.892C144.095 328.844 100.439 393.942 79.622 458.081C48.115 555.123 72.6622 668.274 141.552 743.54"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M-105.045 22.5676C-115.506 58.9924 -96.8766 98.3762 -69.8706 124.965C-42.8646 151.555 -8.29436 168.767 23.4835 189.438C55.2615 210.109 85.9766 236.469 98.0001 272.415C119.38 336.367 75.7243 401.464 54.9072 465.604C23.4002 562.646 47.9473 675.796 116.838 751.063"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
          </svg>
          <svg
            className="shape shape-right text-light"
            viewBox="0 0 731 746"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1794 745.14C1.80036 707.275 -5.75764 666.015 8.73984 629.537C27.748 581.745 80.4729 554.968 131.538 548.843C182.604 542.703 234.032 552.841 285.323 556.748C336.615 560.64 391.543 557.276 433.828 527.964C492.452 487.323 511.701 408.123 564.607 360.255C608.718 320.353 675.307 307.183 731.29 327.323"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M51.0253 745.14C41.2045 709.326 34.0538 670.284 47.7668 635.783C65.7491 590.571 115.623 565.242 163.928 559.449C212.248 553.641 260.884 563.235 309.4 566.931C357.916 570.627 409.887 567.429 449.879 539.701C505.35 501.247 523.543 426.331 573.598 381.059C615.326 343.314 678.324 330.853 731.275 349.906"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M89.8715 745.14C80.6239 711.363 73.8654 674.568 86.8091 642.028C103.766 599.396 150.788 575.515 196.347 570.054C241.906 564.578 287.767 573.629 333.523 577.099C379.278 580.584 428.277 577.567 465.976 551.423C518.279 515.172 535.431 444.525 582.62 401.832C621.964 366.229 681.356 354.493 731.291 372.46"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M128.718 745.14C120.029 713.414 113.678 678.838 125.837 648.274C141.768 608.221 185.939 585.788 228.737 580.659C271.536 575.515 314.621 584.008 357.6 587.282C400.58 590.556 446.607 587.719 482.028 563.16C531.163 529.111 547.275 462.733 591.612 422.635C628.572 389.19 684.375 378.162 731.276 395.043"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M167.564 745.14C159.432 715.451 153.504 683.107 164.863 654.519C179.753 617.046 221.088 596.062 261.126 591.265C301.164 586.452 341.473 594.402 381.677 597.465C421.88 600.527 464.95 597.872 498.094 574.896C544.061 543.035 559.146 480.942 600.617 443.423C635.194 412.135 687.406 401.817 731.276 417.612"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M817.266 289.466C813.108 259.989 787.151 237.697 759.261 227.271C731.372 216.846 701.077 215.553 671.666 210.904C642.254 206.24 611.795 197.156 591.664 175.224C555.853 136.189 566.345 75.5336 560.763 22.8649C552.302 -56.8256 498.487 -130.133 425 -162.081"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M832.584 276.159C828.427 246.683 802.469 224.391 774.58 213.965C746.69 203.539 716.395 202.246 686.984 197.598C657.573 192.934 627.114 183.85 606.982 161.918C571.172 122.883 581.663 62.2275 576.082 9.55873C567.62 -70.1318 513.806 -143.439 440.318 -175.387"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M847.904 262.853C843.747 233.376 817.789 211.084 789.9 200.659C762.011 190.233 731.716 188.94 702.304 184.292C672.893 179.627 642.434 170.544 622.303 148.612C586.492 109.577 596.983 48.9211 591.402 -3.74766C582.94 -83.4382 529.126 -156.746 455.638 -188.694"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <path
              d="M863.24 249.547C859.083 220.07 833.125 197.778 805.236 187.353C777.347 176.927 747.051 175.634 717.64 170.986C688.229 166.321 657.77 157.237 637.639 135.306C601.828 96.2707 612.319 35.6149 606.738 -17.0538C598.276 -96.7443 544.462 -170.052 470.974 -202"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5">
              <div className="section-title">
                <p className="text-primary text-uppercase fw-bold mb-3">
                  How It Works
                </p>
                <h1>See How Wallet Works In One Minute.</h1>
                <p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Consv allis quam aliquet integer eget magna ullam corper
                    peent esque urna. Srna morbi id vitae. Vulpuate nisl
                  </p>
                  <p>
                    sed morbi sit ut placerat eges aeftas et. Pellen tesque
                    tristisque magnis augue gravida pulvinar placerat. Tellus
                    massa pretra scelerisque leo. In faucisfbus egestas. In
                    habitasse sagittis, convallis ut commodo amet.
                  </p>
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="has-video-popup position-relative">
                <img
                  loading="lazy"
                  decoding="async"
                  src={require("../images/video-thumb.png")}
                  alt="video thumb"
                  className="rounded-lg w-100"
                />
                <button
                  type="button"
                  className="video-play-btn border-0 bg-transparent"
                  data-bs-toggle="modal"
                  data-src="https://www.youtube.com/embed/dyZcRRWiuuw"
                  data-bs-target="#videoModal"
                >
                  <svg
                    className="text-primary"
                    viewBox="0 0 90 90"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="45"
                      cy="45"
                      r="45"
                      fill="currentColor"
                      fill-opacity="0.4"
                    />
                    <circle
                      cx="44.9995"
                      cy="45"
                      r="37.437"
                      fill="currentColor"
                    />
                    <path
                      d="M58.2354 44.9999L38.3824 56.462L38.3824 33.5378L58.2354 44.9999Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade rounded overflow-hidden"
        id="videoModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="text-center p-3">
              <button
                type="button"
                className="bg-transparent border-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times"></i>
            </button>
            </div>
            <div className="modal-body p-0">
              <div className="ratio ratio-16x9 rounded-bottom overflow-hidden">
                <iframe
                  src="https://www.youtube.com/embed/WbXPUyHktOI?si=bkAEDxKZ6djdenxl"
                  id="showVideo"
                  allowscriptaccess="always"
                  allow="autoplay"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section loan-steps bg-tertiary">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <p className="text-primary text-uppercase fw-bold mb-3">
                  Apply Loan
                </p>
                <h1>Applying For A Loan Is Very Easy In Just 3 Easy Steps</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-center">
                <div className="step-item col-lg-4 col-md-6">
                  <div className="text-center">
                    <p className="count">01</p>
                    <h3 className="mb-3">Fill Out The Form</h3>
                    <p className="mb-0">
                      Lorem ipsum dolor, consectetur adipiscing. Id egestas
                      sceleriue dui id sed velit facsi eget. Magnis etra.
                    </p>
                  </div>
                </div>
                <div className="step-item col-lg-4 col-md-6">
                  <div className="text-center">
                    <p className="count">02</p>
                    <h3 className="mb-3">Data verification</h3>
                    <p className="mb-0">
                      Lorem ipsum dolor, consectetur adipiscing. Id egestas
                      sceleriue dui id sed velit facsi eget. Magnis etra.
                    </p>
                  </div>
                </div>
                <div className="step-item col-lg-4 col-md-6">
                  <div className="text-center">
                    <p className="count">03</p>
                    <h3 className="mb-3">Get your money</h3>
                    <p className="mb-0">
                      Lorem ipsum dolor, consectetur adipiscing. Id egestas
                      sceleriue dui id sed velit facsi eget. Magnis etra.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <p className="text-primary text-uppercase fw-bold mb-3">
                  Questions You Have
                </p>
                <h1>You Will Get In Wallet</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing . egestas
                  cursus pellentesque dignissim dui, congue. Vel etiam ut
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="icon-box-item col-lg-4 col-md-6">
              <div className="block">
                <div className="icon">
                  {" "}
                  <i className="fas fa-truck"></i>
                </div>
                <h3 className="mb-3">Fast and convenient</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, conse cteatur adipiscing elit. Sit
                  eros, in adipia scing non tellus netus. Pharetra egestas
                  laciansfsia.
                </p>
              </div>
            </div>
            <div className="icon-box-item col-lg-4 col-md-6">
              <div className="block">
                <div className="icon">
                  {" "}
                  <i className="fas fa-shield-alt"></i>
                </div>
                <h3 className="mb-3">Vey Safe and reliable</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, conse cteatur adipiscing elit. Sit
                  eros, in adipia scing non tellus netus. Pharetra egestas
                  laciansfsia.
                </p>
              </div>
            </div>
            <div className="icon-box-item col-lg-4 col-md-6">
              <div className="block">
                <div className="icon">
                  {" "}
                  <i className="fas fa-handshake"></i>
                </div>
                <h3 className="mb-3">Trusted by customers</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, conse cteatur adipiscing elit. Sit
                  eros, in adipia scing non tellus netus. Pharetra egestas
                  laciansfsia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-tertiary">
        <div className="container">
          <div className="row justify-content-center align-items-center mb-5">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <p className="text-primary text-uppercase fw-bold mb-3">
                  QUIESTIONS fOR LOAN
                </p>
                <h1>Additional Informations</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div
                className="accordion accordion-border-bottom"
                id="additional_Info"
              >
                <div className="accordion-item bg-transparent">
                  <h2
                    className="accordion-header accordion-button h5 border-0 active"
                    id="heading-8a4ed081f8ec73b6e9377f609cffed128b925ed9"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-8a4ed081f8ec73b6e9377f609cffed128b925ed9"
                    aria-expanded="true"
                    aria-controls="collapse-8a4ed081f8ec73b6e9377f609cffed128b925ed9"
                  >
                    How can I integrate Avocode to my current tool stack?
                  </h2>
                  <div
                    id="collapse-8a4ed081f8ec73b6e9377f609cffed128b925ed9"
                    className="accordion-collapse collapse border-0 show"
                    aria-labelledby="heading-8a4ed081f8ec73b6e9377f609cffed128b925ed9"
                    data-bs-parent="#additional_Info"
                  >
                    <div className="accordion-body py-0 content">
                      <h6 id="service-provided-as-is">
                        Service Provided AS IS:
                      </h6>
                      <p>
                        The Service is provided for free during this pilot
                        project, and is provided as is with no warranty.
                        Conclude will provide User support for the Service,
                        however; Conclude is not committed to any level of
                        service or availability of the Service. A further
                        description of the Service and our user support is
                        available at the Site.
                      </p>
                      <h6 id="company-liability">Company Liability:</h6>
                      <p>
                        If you enter into this agreement on behalf of a company,
                        you hereby agree that the company is responsible under
                        this Agreement for all actions and omissions conducted
                        by its designated users of the Service.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent">
                  <h2
                    className="accordion-header accordion-button h5 border-0 "
                    id="heading-5547386a6e7381108d294d5eebe26d464fcd8c2c"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-5547386a6e7381108d294d5eebe26d464fcd8c2c"
                    aria-expanded="false"
                    aria-controls="collapse-5547386a6e7381108d294d5eebe26d464fcd8c2c"
                  >
                    How can I use Avocode with cloud documents?
                  </h2>
                  <div
                    id="collapse-5547386a6e7381108d294d5eebe26d464fcd8c2c"
                    className="accordion-collapse collapse border-0 "
                    aria-labelledby="heading-5547386a6e7381108d294d5eebe26d464fcd8c2c"
                    data-bs-parent="#additional_Info"
                  >
                    <div className="accordion-body py-0 content">
                      The difference between and premium product consist number
                      of components, plugins, page in each. The Free versions
                      contain only a few elements and pages that.
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent">
                  <h2
                    className="accordion-header accordion-button h5 border-0 "
                    id="heading-c9475aed4d896279ec43e21b49f55179d31b95ee"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-c9475aed4d896279ec43e21b49f55179d31b95ee"
                    aria-expanded="false"
                    aria-controls="collapse-c9475aed4d896279ec43e21b49f55179d31b95ee"
                  >
                    If I cancel, can I archive my designs to keep them safe
                    until I come back?
                  </h2>
                  <div
                    id="collapse-c9475aed4d896279ec43e21b49f55179d31b95ee"
                    className="accordion-collapse collapse border-0 "
                    aria-labelledby="heading-c9475aed4d896279ec43e21b49f55179d31b95ee"
                    data-bs-parent="#additional_Info"
                  >
                    <div className="accordion-body py-0 content">
                      The difference between and premium product consist number
                      of components, plugins, page in each. The Free versions
                      contain only a few elements and pages that.
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent">
                  <h2
                    className="accordion-header accordion-button h5 border-0 "
                    id="heading-202f9ad39223c3254b19b10ed0e635bc52686306"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-202f9ad39223c3254b19b10ed0e635bc52686306"
                    aria-expanded="false"
                    aria-controls="collapse-202f9ad39223c3254b19b10ed0e635bc52686306"
                  >
                    How can I adjust user permissions &amp; admin provileges?
                  </h2>
                  <div
                    id="collapse-202f9ad39223c3254b19b10ed0e635bc52686306"
                    className="accordion-collapse collapse border-0 "
                    aria-labelledby="heading-202f9ad39223c3254b19b10ed0e635bc52686306"
                    data-bs-parent="#additional_Info"
                  >
                    <div className="accordion-body py-0 content">
                      The difference between and premium product consist number
                      of components, plugins, page in each. The Free versions
                      contain only a few elements and pages that.
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent">
                  <h2
                    className="accordion-header accordion-button h5 border-0 "
                    id="heading-85282f95ad03ef14f79af368179d94939e4ed160"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-85282f95ad03ef14f79af368179d94939e4ed160"
                    aria-expanded="false"
                    aria-controls="collapse-85282f95ad03ef14f79af368179d94939e4ed160"
                  >
                    How do I get back to Avocode and resume my subscription?
                  </h2>
                  <div
                    id="collapse-85282f95ad03ef14f79af368179d94939e4ed160"
                    className="accordion-collapse collapse border-0 "
                    aria-labelledby="heading-85282f95ad03ef14f79af368179d94939e4ed160"
                    data-bs-parent="#additional_Info"
                  >
                    <div className="accordion-body py-0 content">
                      The difference between and premium product consist number
                      of components, plugins, page in each. The Free versions
                      contain only a few elements and pages that.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default How;
